import { ASSESSMENT_FORM_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { ReturnLink } from 'components/Shared/ReturnLink';
import { useGetLatestFormAndTemplate } from 'hooks/useGetLatestFormAndTemplate';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { AssessmentForm } from './AssessmentForm';

export function AssessmentPage() {
  const { search } = useLocation();

  const isNew = new URLSearchParams(search).has('new');

  const { loading, templateName, formlyConfig, form, formData, error, refetch } =
    useGetLatestFormAndTemplate(ASSESSMENT_FORM_ID, { isNewInstance: isNew });

  return (
    <>
      <div className="assessment">
        <section>
          <h3>
            Assessment <ReturnLink />
          </h3>

          {loading && (
            <div className="assessment-loader">
              <p>Retrieving the form...</p>

              <ActivityIndicator showProgressBar />
            </div>
          )}

          {error && <ErrorDisplay error={error} />}

          {formlyConfig && templateName && (
            <AssessmentForm
              formId={form?.id}
              formlyConfig={formlyConfig}
              model={formData}
              isSubmitted={Boolean(form?.isSubmitted)}
              templateName={templateName}
              refetch={refetch}
            />
          )}
        </section>
      </div>
    </>
  );
}

