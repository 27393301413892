import { Box, Typography } from '@material-ui/core';
import { useGetFormTemplateFieldOptionDetails } from 'apollo-hooks';
import { ASSESSMENT_FORM_ID, MODULE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getScoreFromOptionValue } from 'utils';
import { AdminPageTitle } from '../AdminPageTitle';
import { RecommendationEditor } from './RecommendationEditor';

export function ManageFieldRecommendationsPage() {
  const { fieldId } = useParams<{ fieldId: string }>();

  const getFormTemplateState = useGetFormTemplateFieldOptionDetails({
    siteId: 1,
    module: MODULE_ID,
    id: ASSESSMENT_FORM_ID,
  });

  const [formlyConfig, setFormlyConfig] = useState<IFormlyConfig>();

  useEffect(() => {
    if (getFormTemplateState.data?.formTemplate?.template) {
      setFormlyConfig(JSON.parse(getFormTemplateState.data.formTemplate.template));
    }
  }, [getFormTemplateState.data]);

  const field = formlyConfig?.fields
    .flatMap((field) => field.fieldGroup)
    .flatMap((field) => field?.fieldGroup)
    .find((field) => field?.key === fieldId);

  const recommendations = getFormTemplateState.data?.formTemplate?.fieldOptionDetails?.filter(
    (detail) => detail.fieldId === fieldId,
  );

  return (
    <>
      <AdminPageTitle
        title="Manage recommendations"
        crumbs={[{ to: '/admin/recommendations', name: 'Manage recommendations' }]}
      />
      <div className="page">
        <section>
          {getFormTemplateState.loading && (
            <Box>
              <ActivityIndicator />
            </Box>
          )}
          {formlyConfig && !field && <ErrorDisplay errorMessage="Field not found." />}
          {field && (
            <>
              <Typography component="h3">{field.templateOptions?.label}</Typography>

              {field.templateOptions?.options?.map((option, index) => (
                <Box key={index} mb={2}>
                  <Typography gutterBottom>
                    Option {index + 1}: <b>{option.name}</b>
                  </Typography>
                  <Typography gutterBottom>
                    Score: <b>{getScoreFromOptionValue(option.value) ?? '(not set)'}</b>
                  </Typography>
                  <Typography>Recommendation:</Typography>
                  <RecommendationEditor
                    fieldId={fieldId}
                    index={index}
                    recommendation={recommendations?.find((rec) => rec.index === index)?.detail}
                  />
                </Box>
              ))}
            </>
          )}
          <Link to="/admin/recommendations">Back to Manage recommendations</Link>
        </section>
      </div>
    </>
  );
}

