import {
  ASSESSMENT_FORM_ID,
  ASSESSMENT_TOTAL_FIELD_ID,
  RECOMMENDATIONS_REPORT_ID,
} from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { ReturnLink } from 'components/Shared/ReturnLink';
import { ReportIdType, useRunReport } from 'hooks/Reports/useRunReport';
import { useGetLatestFormAndTemplate } from 'hooks/useGetLatestFormAndTemplate';
import React, { useEffect } from 'react';
import { Report } from './Report';

export function RecommendationsPage() {
  const formState = useGetLatestFormAndTemplate(ASSESSMENT_FORM_ID);
  const { runReport, runReportState } = useRunReport();

  useEffect(() => {
    runReport(RECOMMENDATIONS_REPORT_ID, ReportIdType.Report, {});
  }, []);

  const assessmentScore = Number(formState.formData?.[ASSESSMENT_TOTAL_FIELD_ID] ?? 0);
  const isCritical = assessmentScore < -1000;

  return (
    <>
      <div className="recommendations advice-and-recommendations">
        <section>
          <h3>
            Advice and Recommendations <ReturnLink />
          </h3>

          {(formState.loading || runReportState.loading) && <ActivityIndicator showProgressBar />}

          {formState.error && <ErrorDisplay error={formState.error} />}
          {runReportState.error && <ErrorDisplay error={runReportState.error} />}

          {formState.formData && runReportState.data && (
            <div>
              {isCritical && (
                <div>
                  <p>
                    Your response to one or more of the questions has indicated that a policy or
                    practice within your business may be unlawful. Follow these steps:
                  </p>
                  <ol>
                    <li>See which question or questions require your attention below.</li>
                    <li>
                      Read the advice and recommendations, and take the required steps to address
                      any unlawful practice.
                    </li>
                    <li>
                      Once you have put in place the recommended changes, update your answer in the
                      assessment. You can jump straight to the relevant question by clicking the{' '}
                      <strong>update your answer in the assessment</strong> link under the advice
                      and recommendations.
                    </li>
                  </ol>
                </div>
              )}

              <Report data={runReportState.data} />
            </div>
          )}
        </section>
      </div>
    </>
  );
}

