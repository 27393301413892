import { List } from '@material-ui/core';
import { useGetFormTemplateFieldOptionDetails } from 'apollo-hooks';
import { ASSESSMENT_FORM_ID, MODULE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import React, { useEffect, useState } from 'react';
import { AdminPageTitle } from '../AdminPageTitle';
import { Section } from './Section';

export function ManageRecommendationsPage() {
  const getFormTemplateState = useGetFormTemplateFieldOptionDetails({
    siteId: 1,
    module: MODULE_ID,
    id: ASSESSMENT_FORM_ID,
  });

  const [formlyConfig, setFormlyConfig] = useState<IFormlyConfig>();

  useEffect(() => {
    if (getFormTemplateState.data?.formTemplate?.template) {
      setFormlyConfig(JSON.parse(getFormTemplateState.data.formTemplate.template));
    }
  }, [getFormTemplateState.data]);

  return (
    <>
      <AdminPageTitle title="Manage recommendations" />
      <div className="page">
        <section>
          {getFormTemplateState.loading && <ActivityIndicator />}
          {formlyConfig && (
            <List>
              {formlyConfig.fields
                .flatMap((field) => field.fieldGroup)
                .map((field, index) => (
                  <Section key={index} field={field} isTopLevel />
                ))}
            </List>
          )}
        </section>
      </div>
    </>
  );
}

