import { useGetSiteAndChildren } from 'apollo-hooks';
import { AuthenticatedRoute } from 'auth/AuthenticatedRoute';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { PageTitle } from 'components/Shared/PageTitle';
import { UpdateMarketingPreferencesDialogContainer } from 'components/Shared/UpdateMarketingPreferencesDialogContainer';
import { getChainPath } from 'paths';
import React, { useContext } from 'react';
import { Redirect, Switch, useParams } from 'react-router';
import { CHAIN_SITE_TYPE, GROUP_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';
import { PayPage } from './PayPage';
import { ChainVenuesDetailsPage } from './Venues/ChainVenuesDetailsPage';
import { ChainVenuesManagePage } from './Venues/ChainVenuesManagePage';

export function ChainPage() {
  const { chainId: chainIdString, action } = useParams<{ chainId: string; action: string }>();
  const chainId = Number(chainIdString);

  const userProfile = useContext(UserProfileContext)!;

  const { loading, error, data } = useGetSiteAndChildren({ id: chainId });

  if (!userProfile.hasAnyPermission(['Admin']) && !userProfile.siteIds.includes(chainId)) {
    return (
      <div className="page">
        <ErrorDisplay error="You do not have permission to access this chain." />
      </div>
    );
  }

  if (!action) {
    return <Redirect to={getChainPath(chainId, '/details')} />;
  }

  const chainSite = data?.siteAndChildren?.find((site) => site.id === chainId);

  return (
    <>
      <UpdateMarketingPreferencesDialogContainer />

      <PageTitle title={chainSite?.name ?? 'Manage chain'} />
      <div className="page">
        <section>
          {loading && <ActivityIndicator showProgressBar />}
          {error && <ErrorDisplay error={error} />}
          {chainSite && (
            <>
              {chainSite.type === CHAIN_SITE_TYPE || chainSite.type === GROUP_SITE_TYPE ? (
                <>
                  <Switch>
                    <AuthenticatedRoute
                      exact
                      path="/chains/:chainId/details"
                      component={ChainVenuesDetailsPage}
                    />
                    <AuthenticatedRoute
                      exact
                      path="/chains/:chainId/manage"
                      component={ChainVenuesManagePage}
                    />
                    <AuthenticatedRoute exact path="/chains/:chainId/pay" component={PayPage} />
                  </Switch>
                </>
              ) : (
                <ErrorDisplay error="This site is not a chain." />
              )}
            </>
          )}
        </section>
      </div>
    </>
  );
}
