/* eslint-disable max-len */
import { DataProxy, useMutation, useQuery } from '@apollo/client';
import {
  CreateFormDocument,
  CreateFormMutation,
  CreateInvitationDocument,
  CreateInvitationMutation,
  CreateLibraryFilesDocument,
  CreateLibraryFilesMutation,
  CreateSiteDocument,
  CreateSiteMutation,
  GetBuildNumberDocument,
  GetBuildNumberQuery,
  GetFormDocument,
  GetFormQuery,
  GetFormQueryVariables,
  GetFormsDocument,
  GetFormsQuery,
  GetFormsQueryVariables,
  GetFormTemplateAndInstancesDocument,
  GetFormTemplateAndInstancesQuery,
  GetFormTemplateAndInstancesQueryVariables,
  GetFormTemplateDocument,
  GetFormTemplateFieldOptionDetailsDocument,
  GetFormTemplateFieldOptionDetailsQuery,
  GetFormTemplateFieldOptionDetailsQueryVariables,
  GetFormTemplateQuery,
  GetFormTemplateQueryDefinitionDocument,
  GetFormTemplateQueryDefinitionQuery,
  GetFormTemplateQueryDefinitionQueryVariables,
  GetFormTemplateQueryVariables,
  GetFormTemplatesByTagDocument,
  GetFormTemplatesByTagQuery,
  GetFormTemplatesByTagQueryVariables,
  GetFormTemplatesDocument,
  GetFormTemplatesQuery,
  GetFormTemplatesQueryVariables,
  GetLibraryFilesDocument,
  GetLibraryFilesQuery,
  GetLibraryFilesQueryVariables,
  GetLibraryFolderDocument,
  GetLibraryFolderQuery,
  GetLibraryFolderQueryVariables,
  GetMyCustomDataDocument,
  GetMyCustomDataQuery,
  GetMyReportDocument,
  GetMyReportQuery,
  GetMyReportQueryVariables,
  GetMyReportsDocument,
  GetMyReportsQuery,
  GetMyReportsQueryVariables,
  GetMySitesDocument,
  GetMySitesQuery,
  GetRolesDocument,
  GetRolesQuery,
  GetSiteAdminDocument,
  GetSiteAdminQuery,
  GetSiteAdminQueryVariables,
  GetSiteAndChildrenDocument,
  GetSiteAndChildrenQuery,
  GetSiteAndChildrenQueryVariables,
  GetSiteDocument,
  GetSiteQuery,
  GetSiteQueryVariables,
  GetSitesDocument,
  GetSitesQuery,
  GetSitesQueryVariables,
  GetUserAdminDocument,
  GetUserAdminQuery,
  GetUserAdminQueryVariables,
  GetUsersAdminDocument,
  GetUsersAdminQuery,
  GetUsersAdminQueryVariables,
  UpdateFormDocument,
  UpdateFormMutation,
  UpdateFormTemplateFieldOptionDetailDocument,
  UpdateFormTemplateFieldOptionDetailMutation,
  UpdateMyCustomDataDocument,
  UpdateMyCustomDataMutation,
  UpdateSiteDocument,
  UpdateSiteMutation,
  UpdateUserCustomDataDocument,
  UpdateUserCustomDataMutation,
  UpdateUserDocument,
  UpdateUserMutation,
} from './tillr-graphql';

// This deleteCacheForQuery is a hack until it's supported in Apollo Client
interface DataProxyExt extends DataProxy {
  data?: { data?: { ROOT_QUERY: { [key: string]: string } } };
}
const deleteCacheForQuery = (queryName: string) => (proxy: DataProxyExt) => {
  if (proxy.data?.data?.ROOT_QUERY) {
    Object.keys(proxy.data.data?.ROOT_QUERY)
      .filter((x) => x.split(':')[0] === queryName)
      // eslint-disable-next-line no-param-reassign
      .forEach((x) => delete proxy.data!.data!.ROOT_QUERY[x]);
  }
};

export function useCreateForm() {
  return useMutation<CreateFormMutation>(CreateFormDocument, {
    onError: () => {},
    // Forms list is paginated, delete from cache so it will refetch
    update: deleteCacheForQuery('forms'),
  });
}

export function useCreateInvitation(variables?: {
  getSiteAdminQueryVariables?: GetSiteAdminQueryVariables;
  getUsersAdminQueryVariables?: GetUsersAdminQueryVariables;
}) {
  return useMutation<CreateInvitationMutation>(CreateInvitationDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createInvitation) {
        const { getSiteAdminQueryVariables, getUsersAdminQueryVariables } = variables ?? {};

        if (getSiteAdminQueryVariables) {
          const options = { query: GetSiteAdminDocument, variables: getSiteAdminQueryVariables };
          const existingData = cache.readQuery<GetSiteAdminQuery>(options);
          if (
            existingData?.siteAdmin &&
            updatedData.createInvitation.sites.some((x) => x.id === getSiteAdminQueryVariables.id)
          ) {
            cache.writeQuery({
              query: GetSiteAdminDocument,
              variables: getSiteAdminQueryVariables,
              data: {
                ...existingData,
                siteAdmin: {
                  ...existingData.siteAdmin,
                  pendingInvitations: (existingData.siteAdmin.pendingInvitations ?? []).concat([
                    updatedData.createInvitation,
                  ]),
                },
              },
            });
          }
        }

        if (getUsersAdminQueryVariables) {
          const options = { query: GetUsersAdminDocument, variables: getUsersAdminQueryVariables };
          const existingData = cache.readQuery<GetUsersAdminQuery>(options);
          if (existingData?.pendingInvitations) {
            cache.writeQuery({
              query: GetUsersAdminDocument,
              variables: getUsersAdminQueryVariables,
              data: {
                ...existingData,
                pendingInvitations: existingData.pendingInvitations.concat([
                  updatedData.createInvitation,
                ]),
              },
            });
          }
        }
      }
    },
  });
}

export function useCreateLibraryFiles(variables: GetLibraryFolderQueryVariables) {
  return useMutation<CreateLibraryFilesMutation>(CreateLibraryFilesDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createLibraryFiles) {
        const options = { query: GetLibraryFolderDocument, variables };
        const existingData = cache.readQuery<GetLibraryFolderQuery>(options);
        if (existingData?.libraryFolder) {
          const libraryFolderNext = {
            ...existingData.libraryFolder,
            files: existingData.libraryFolder.files.concat(updatedData.createLibraryFiles.files),
          };
          cache.writeQuery({
            query: GetLibraryFolderDocument,
            variables,
            data: { libraryFolder: libraryFolderNext },
          });
        }
      }
    },
  });
}

export function useCreateSite(variables?: {
  getSiteAndChildrenQueryVariables?: GetSiteAndChildrenQueryVariables;
  getSitesQueryVariables?: GetSitesQueryVariables;
}) {
  return useMutation<CreateSiteMutation>(CreateSiteDocument, {
    onError: () => {},
    update(cache, { data: updatedData, errors }) {
      if (!errors?.length && updatedData?.createSite) {
        const { getSiteAndChildrenQueryVariables, getSitesQueryVariables } = variables ?? {};
        if (getSiteAndChildrenQueryVariables) {
          const getSitesAndChildrenData = cache.readQuery<GetSiteAndChildrenQuery>({
            query: GetSiteAndChildrenDocument,
            variables: getSiteAndChildrenQueryVariables,
          });

          if (getSitesAndChildrenData?.siteAndChildren) {
            cache.writeQuery({
              query: GetSiteAndChildrenDocument,
              variables: getSiteAndChildrenQueryVariables,
              data: {
                siteAndChildren: getSitesAndChildrenData.siteAndChildren.concat([
                  { ...updatedData.createSite, __typename: 'SiteType' },
                ]),
              },
            });
          }
        }

        if (getSitesQueryVariables) {
          const getSitesData = cache.readQuery<GetSitesQuery>({
            query: GetSitesDocument,
            variables: getSitesQueryVariables,
          });
          if (getSitesData?.sites) {
            if (
              updatedData.createSite.parentSiteId === getSitesQueryVariables.parentSiteId &&
              updatedData.createSite.type === getSitesQueryVariables.type
            ) {
              cache.writeQuery({
                query: GetSitesDocument,
                variables: getSitesQueryVariables,
                data: {
                  sites: getSitesData.sites.concat([
                    { ...updatedData.createSite, __typename: 'SiteType' },
                  ]),
                },
              });
            }
          }
        }
      }
    },
  });
}

export function useGetBuildNumber() {
  return useQuery<GetBuildNumberQuery>(GetBuildNumberDocument);
}

export function useGetFormTemplate(variables: GetFormTemplateQueryVariables) {
  return useQuery<GetFormTemplateQuery>(GetFormTemplateDocument, {
    variables,
    skip: !variables.id,
  });
}

export function useGetFormTemplateFieldOptionDetails(
  variables: GetFormTemplateFieldOptionDetailsQueryVariables,
) {
  return useQuery<GetFormTemplateFieldOptionDetailsQuery>(
    GetFormTemplateFieldOptionDetailsDocument,
    { variables },
  );
}

export function useGetFormTemplateQueryDefinition(
  variables: GetFormTemplateQueryDefinitionQueryVariables,
) {
  return useQuery<GetFormTemplateQueryDefinitionQuery>(GetFormTemplateQueryDefinitionDocument, {
    skip: !variables.id,
    variables,
  });
}

export function useGetFormTemplates(variables: GetFormTemplatesQueryVariables) {
  return useQuery<GetFormTemplatesQuery>(GetFormTemplatesDocument, { variables });
}

export function useGetFormTemplatesByTag(variables: GetFormTemplatesByTagQueryVariables) {
  return useQuery<GetFormTemplatesByTagQuery>(GetFormTemplatesByTagDocument, { variables });
}

export function useGetLibraryFiles(variables: GetLibraryFilesQueryVariables) {
  return useQuery<GetLibraryFilesQuery>(GetLibraryFilesDocument, {
    skip: !variables.searchQuery,
    variables,
  });
}

export function useGetLibraryFolder(variables: GetLibraryFolderQueryVariables) {
  return useQuery<GetLibraryFolderQuery>(GetLibraryFolderDocument, { variables });
}

export function useGetForm(variables: GetFormQueryVariables) {
  return useQuery<GetFormQuery>(GetFormDocument, { variables, skip: !variables.id });
}

export function useGetFormTemplateAndInstances(
  variables: GetFormTemplateAndInstancesQueryVariables,
  skip?: boolean,
) {
  return useQuery<GetFormTemplateAndInstancesQuery>(GetFormTemplateAndInstancesDocument, {
    variables,
    skip,
    notifyOnNetworkStatusChange: true,
  });
}

export function useGetForms(variables: GetFormsQueryVariables) {
  return useQuery<GetFormsQuery>(GetFormsDocument, { variables });
}

export function useGetMyReport(variables: GetMyReportQueryVariables) {
  return useQuery<GetMyReportQuery>(GetMyReportDocument, { variables, skip: !variables.id });
}

export function useGetMyReports(variables: GetMyReportsQueryVariables) {
  return useQuery<GetMyReportsQuery>(GetMyReportsDocument, { variables });
}

export function useGetMySites() {
  return useQuery<GetMySitesQuery>(GetMySitesDocument);
}

export function useGetMyCustomData(skip?: boolean) {
  return useQuery<GetMyCustomDataQuery>(GetMyCustomDataDocument, { skip });
}

export function useGetRoles() {
  return useQuery<GetRolesQuery>(GetRolesDocument);
}

export function useGetSite(variables: GetSiteQueryVariables) {
  return useQuery<GetSiteQuery>(GetSiteDocument, { variables });
}

export function useGetSiteAdmin(variables: GetSiteAdminQueryVariables) {
  return useQuery<GetSiteAdminQuery>(GetSiteAdminDocument, { variables });
}

export function useGetSiteAndChildren(variables: GetSiteAndChildrenQueryVariables) {
  return useQuery<GetSiteAndChildrenQuery>(GetSiteAndChildrenDocument, { variables });
}

export function useGetSites(variables: GetSitesQueryVariables) {
  return useQuery<GetSitesQuery>(GetSitesDocument, { variables });
}

export function useGetUserAdmin(variables: GetUserAdminQueryVariables) {
  return useQuery<GetUserAdminQuery>(GetUserAdminDocument, { variables });
}

export function useGetUsersAdmin(variables: GetUsersAdminQueryVariables) {
  return useQuery<GetUsersAdminQuery>(GetUsersAdminDocument, { variables });
}

export function useUpdateForm(variables: GetFormQueryVariables) {
  return useMutation<UpdateFormMutation>(UpdateFormDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateForm) {
        const options = { query: GetFormDocument, variables };
        const existingFormData = cache.readQuery<GetFormQuery>(options);
        if (existingFormData?.form) {
          cache.writeQuery({
            query: GetFormDocument,
            variables,
            data: { form: data.updateForm },
          });
        }
      }
    },
  });
}

export function useUpdateFormTemplateFieldOptionDetail(
  variables: GetFormTemplateFieldOptionDetailsQueryVariables,
) {
  return useMutation<UpdateFormTemplateFieldOptionDetailMutation>(
    UpdateFormTemplateFieldOptionDetailDocument,
    {
      onError: () => {},
      update(cache, { data, errors }) {
        if (!errors?.length && data?.updateFormTemplateFieldOptionDetail) {
          const options = { query: GetFormTemplateFieldOptionDetailsDocument, variables };
          const existing = cache.readQuery<GetFormTemplateFieldOptionDetailsQuery>(options);
          if (existing?.formTemplate) {
            const update = data.updateFormTemplateFieldOptionDetail;
            cache.writeQuery({
              query: GetFormTemplateFieldOptionDetailsDocument,
              variables,
              data: {
                formTemplate: {
                  ...existing.formTemplate,
                  fieldOptionDetails: existing.formTemplate.fieldOptionDetails
                    ?.filter(
                      (field) => field.fieldId !== update.fieldId && field.index !== update.index,
                    )
                    .concat(update),
                },
              },
            });
          }
        }
      },
    },
  );
}

export function useUpdateSite() {
  return useMutation<UpdateSiteMutation>(UpdateSiteDocument, {
    onError: () => {},
    // TODO: update local queries
  });
}

export function useUpdateUser(variables?: {
  getSiteAdminQueryVariables?: GetSiteAdminQueryVariables;
  getUserAdminQueryVariables?: GetUserAdminQueryVariables;
}) {
  return useMutation<UpdateUserMutation>(UpdateUserDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateUser) {
        const { getSiteAdminQueryVariables, getUserAdminQueryVariables } = variables ?? {};

        if (getSiteAdminQueryVariables) {
          const options = { query: GetSiteAdminDocument, variables: getSiteAdminQueryVariables };
          const existingData = cache.readQuery<GetSiteAdminQuery>(options);
          if (
            existingData?.siteAdmin?.users &&
            // Is user here but no longer has access to this site?
            existingData.siteAdmin.users.some((x) => x.id === data.updateUser?.id) &&
            !data.updateUser.sites.some((x) => x.id === getSiteAdminQueryVariables.id)
          ) {
            cache.writeQuery({
              query: GetSiteAdminDocument,
              variables: getSiteAdminQueryVariables,
              data: {
                ...existingData,
                siteAdmin: {
                  ...existingData.siteAdmin,
                  users: existingData.siteAdmin.users.filter((x) => x.id !== data.updateUser?.id),
                },
              },
            });
          }
        }

        if (getUserAdminQueryVariables) {
          const options = { query: GetUserAdminDocument, variables: getUserAdminQueryVariables };
          const existingData = cache.readQuery<GetUserAdminQuery>(options);
          if (existingData?.userAdmin) {
            cache.writeQuery({
              query: GetUserAdminDocument,
              variables: getUserAdminQueryVariables,
              data: { userAdmin: data.updateUser },
            });
          }
        }
      }
    },
  });
}

export function useUpdateMyCustomData() {
  return useMutation<UpdateMyCustomDataMutation>(UpdateMyCustomDataDocument, {
    onError: () => {},
    update(cache, { data, errors }) {
      if (!errors?.length && data?.updateMyCustomData?.customData) {
        const existingData = cache.readQuery<GetMyCustomDataQuery>({
          query: GetMyCustomDataDocument,
        });
        if (existingData?.myIdentity) {
          cache.writeQuery({
            query: GetMyCustomDataDocument,
            data: {
              ...existingData,
              myIdentity: {
                ...existingData.myIdentity,
                customData: data.updateMyCustomData.customData,
              },
            },
          });
        }
      }
    },
  });
}

export function useUpdateUserCustomData() {
  return useMutation<UpdateUserCustomDataMutation>(UpdateUserCustomDataDocument);
}
