import { DialogActions, DialogContentText, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useUpdateSite } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import { CancelButton } from 'components/Shared/CancelButton';
import React, { useEffect, useState } from 'react';
import { SiteItemFragment, UpdateSiteMutationVariables } from 'tillr-graphql';
import { CHAIN_SITE_TYPE, GROUP_SITE_TYPE } from 'types';

interface IProps {
  site: SiteItemFragment;
  onCloseMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sites: Array<SiteItemFragment>;
  refetch: () => void;
  inline?: boolean | undefined;
}

export function MoveVenue(props: IProps) {
  const { site, onCloseMenu, sites, refetch, inline } = props;
  const [open, setOpen] = useState(false);

  const [updateSite, { loading, data, error }] = useUpdateSite();

  useEffect(() => {
    if (data?.updateSite) {
      refetch();
      setOpen(false);
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (newParentSiteId: number) => {
    const variables: UpdateSiteMutationVariables = {
      site: {
        id: site.id,
        name: site.name,
        type: site.type,
        parentSiteId: newParentSiteId,
      },
    };
    updateSite({ variables });
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    onCloseMenu(event);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant={inline ? 'outlined' : 'text'}
        size={inline ? 'small' : 'medium'}
      >
        Move
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Move "{site.name}"</DialogTitle>
        <DialogContent>
          {error && <ErrorDisplay error={error} />}
          <DialogContentText>Select the parent group for this venue.</DialogContentText>
          <Grid container spacing={1}>
            {[
              // Chain first
              ...sites.filter((x) => x.type === CHAIN_SITE_TYPE),
              // then Groups in alphabetical order
              ...sites
                .filter((x) => x.type === GROUP_SITE_TYPE)
                .sort((a, b) => a.name.localeCompare(b.name)),
            ]
              .filter((x) => x.id !== site.parentSiteId)
              .map((parentSite) => (
                <Grid key={parentSite.id} item>
                  <Button
                    variant="outlined"
                    onClick={() => handleSubmit(parentSite.id)}
                    disabled={loading}
                  >
                    {parentSite.name}
                  </Button>
                </Grid>
              ))}
          </Grid>
          <DialogActions>
            <CancelButton onClick={handleClose} />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
