import { useState } from 'react';

type IdealPostcodesApiAddress = {
  organisation_name: string;
  line_1: string;
  line_2: string;
  post_town: string;
  postcode: string;
};

type IdealPostcodesApiResults = {
  result: {
    total: number;
    hits: Array<IdealPostcodesApiAddress>;
  };
};

type Address = {
  name: string;
  buildingName: string;
  line1: string;
  line2: string;
  town: string;
  postcode: string;
};

type Results = {
  totalHits: number;
  addresses: Array<Address>;
};

interface IState {
  search: (postcode: string) => void;
  searching: boolean;
  results?: Results;
  error?: string;
}

export function usePostcodeLookup(): IState {
  const apiKey = `ak_kea1kr4oU5BuJsH8RSy3d2RjGu5uO`;

  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState<Results>();
  const [error, setError] = useState<string>();

  const search = (postcode: string) => {
    setSearching(true);
    setError(undefined);

    fetch(`https://api.ideal-postcodes.co.uk/v1/addresses?q=${postcode}`, {
      headers: {
        Authorization: `IDEALPOSTCODES api_key="${apiKey}"`,
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(({ result }: IdealPostcodesApiResults) => {
            const addresses: Array<Address> = result.hits.map((item) => {
              let name = '';
              if (item.organisation_name && item.organisation_name.length) {
                name += `${item.organisation_name}, `;
              }
              if (item.line_1 && item.line_1.length) {
                name += `${item.line_1}, `;
              }
              if (item.post_town && item.post_town.length) {
                name += `${item.post_town}`;
              }
              if (item.postcode && item.postcode.length) {
                name += ` ${item.postcode}`;
              }

              return {
                name,
                buildingName: item.organisation_name,
                line1: item.line_1,
                line2: item.line_2,
                town: item.post_town,
                postcode: item.postcode,
              };
            });

            setResults({ totalHits: result.total, addresses });
          });
        } else {
          response.json().then(({ message }) => setError(`An error occurred: ${message}`));
        }
      })
      .catch(() =>
        setError(
          'We are unable to connect to the address search. Please enter your address manually or try later.',
        ),
      )
      .finally(() => setSearching(false));
  };

  return { search, searching, results, error };
}

