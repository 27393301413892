import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserProfileContext from 'UserProfileContext';

export function ReturnLink() {
  const userProfile = useContext(UserProfileContext)!;
  if (userProfile.hasAnyPermission(['Reviewer'])) {
    return (
      <Link to="/review">
        <button type="button">return to your review</button>
      </Link>
    );
  }

  if (userProfile.hasAnyPermission(['Admin'])) {
    return (
      <Link to="/admin">
        <button type="button">return to admin dashboard</button>
      </Link>
    );
  }

  if (userProfile.hasAnyPermission(['Chain.Manage'])) {
    return (
      <Link to="/chains">
        <button type="button">return to manage chain</button>
      </Link>
    );
  }

  return (
    <Link to="/journey">
      <button type="button">return to your accreditation journey</button>
    </Link>
  );
}

