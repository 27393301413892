/* eslint react/prop-types: 0 */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CollapsableGroup from './components/CollapsableGroup';
import evaluateFormula from './evaluateFormula';
import { FormMode } from './FormMode';
// eslint-disable-next-line import/no-cycle
import MandatoryFieldMessage from 'components/Shared/MandatoryFieldMessage';
import getFieldTypeComponent from './getFieldTypeComponent';
import { IComponentProps } from './IComponentProps';
import { IFormlyConfig, IFormlyField } from './IFormlyConfig';
import { IModel } from './IModel';

interface IProps {
  config: IFormlyConfig;
  formId?: string;
  mode?: FormMode;
  model?: IModel;
  onCancel?: () => void;
  onSubmit?: (model: any) => void;
  submitting?: boolean;
  children?: React.ReactNode;
  customFunctions?: ((...args: any[]) => void)[];
  customButtons?: boolean;
  readonlyKeys?: Array<string>;
  extraRequiredKeys?: Array<string>;
  onChange?: (model: IModel) => void;
  hideMandatoryFieldMessage?: boolean;
  step?: number;
  redSaveButton?: boolean;
  redSaveButtonLabel?: string;
  ignoreDirty?: boolean;
}

export default function FormlyForm(props: IProps) {
  const { handleSubmit, formState, clearErrors, unregister, ...useFormProps } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    config: { fields, settings },
    formId,
    mode = 'create',
    model: initialModel = {},
    onCancel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit,
    submitting,
    children,
    customFunctions = [],
    customButtons = false,
    readonlyKeys,
    extraRequiredKeys,
    onChange,
    hideMandatoryFieldMessage = false,
    step,
    redSaveButton = false,
    redSaveButtonLabel = 'Save',
    ignoreDirty = false,
  } = props;

  let toRemove: string[] = [];

  function initialiseScoredCheckboxesModel(values: Array<number | null>) {
    const total = values.map((x) => x || 0).reduce((a, b) => a + b);
    return { model: values, total };
  }

  function initialiseModel(model: any, formFields: IFormlyField[]) {
    formFields.forEach((x) => {
      if (x.key && model[x.key] === undefined && x.defaultValue != null) {
        if (x?.type?.toLowerCase().match(/scoredcheckbox/)) {
          // Scored checkboxes have a different model to all the rest,
          // it has { model: number[], total: number }
          // eslint-disable-next-line no-param-reassign
          model[x.key] = initialiseScoredCheckboxesModel(x.defaultValue);
        } else if (x?.type?.toLowerCase().match(/timepicker/)) {
          // An unset timepicker is given an empty string default value by
          // Form Builder. But an empty string means it exists in the form's model
          // when the form initialises
          if (x.defaultValue.length) {
            // eslint-disable-next-line no-param-reassign
            model[x.key] = x.defaultValue;
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          model[x.key] = x.defaultValue;
        }
      }
      if (x.fieldGroup) {
        initialiseModel(model, x.fieldGroup);
      }
    });
  }

  initialiseModel(initialModel, fields);

  const [model, setModel] = useState(initialModel);

  useEffect(() => {
    if (onChange) {
      onChange(model);
    }
  }, [model]);

  async function handleOnSubmit(): Promise<void> {
    // Filter model and automated tasks
    // to only include elements that are
    // still part of the form (have not
    // since been hidden by any fired
    // dynamic rules)
    const newModel = await new Promise((resolve) => {
      const filtered = { ...model };
      // eslint-disable-next-line array-callback-return
      toRemove.map((key) => {
        // Remove from model
        if (typeof filtered[key] !== 'undefined') {
          delete filtered[key];
        }
      });
      resolve(filtered);
    });

    // No new automated tasks, so crack on
    if (onSubmit) {
      onSubmit(newModel);
    }

    useFormProps.reset(newModel as any);
  }

  // If an element is hidden, via a rule, it musn't appear in the model
  const removeFromModel = (key: string | undefined) => {
    if (key && model[key] !== undefined) {
      // const { [key]: value, ...updated } = model;
      // setModel(updated);
      if (!toRemove.includes(key)) {
        toRemove = toRemove.concat([key]);
      }
    }
  };

  // If an element is hidden, via a rule, it musn't be validated
  const removeFromValidation = (key: string | undefined) => {
    if (!key) return;
    unregister(key);
  };

  const onValueUpdate = (fieldKey: string, value: any) => {
    setModel((prevState) => ({ ...prevState, [fieldKey]: value }));
  };

  const fireDynamicRule = (hideExpression: string, key: string | undefined) => {
    try {
      // eslint-disable-next-line no-new-func, @typescript-eslint/no-implied-eval
      const func = new Function('model', `return ${hideExpression}`);
      if (func(model)) {
        removeFromModel(key);
        removeFromValidation(key);
        return null;
      }
      // This is where we can fix the default value for hidden then
      // shown elements
    } catch {
      // If there was an error, the key doesn't exist in the model. BUT
      // this might be what we're testing for, so we can't *just* return null
      try {
        // Have to get the key like this as, if someone were to enter
        // a GUID in the field, it woudn't work. Have to match on "model['..."
        const field = hideExpression.match(/^model\['[A-z0-9-]+/);
        if (field) {
          const fieldKey = field[0].split("model['");
          // If the key isn't in the model, determine if we are looking for
          // a particular value to be present. If we are, it's obviously false
          if (!model[fieldKey[1]]) {
            return hideExpression.match(/===/);
          }
          return null;
        }
      } catch {
        // If we still have an error, something is wrong, so NOW
        // we can just return null
        return null;
      }
      return null;
    }
    return true;
  };

  function renderFields(
    fieldGroup: IFormlyField[],
    hideGroup?: boolean,
    groupStep?: number,
  ): (JSX.Element | null)[] {
    let groupIsHidden: boolean = hideGroup || false;

    return fieldGroup.map((field, i) => {
      const key = `fieldgroup_${i}`;

      if (field.fieldGroup?.length) {
        // If this is a group and it has a hideExpression, store the outcome
        // so we can remove it's children from the model and validation
        if (field?.className?.match(/group/) && field.hideExpression) {
          groupIsHidden = !fireDynamicRule(field.hideExpression, field.data?.key);
        }

        const childFields = renderFields(field.fieldGroup, groupIsHidden);
        if (childFields.some((x) => x != null) && (groupStep === undefined || i === groupStep)) {
          if (settings?.collapseGroups === true && field?.data?.format === 'group') {
            return (
              <CollapsableGroup key={key} field={field} model={model}>
                {childFields}
              </CollapsableGroup>
            );
          }
          return <React.Fragment key={key}>{childFields}</React.Fragment>;
        }

        // All children complete, so reset
        groupIsHidden = false;

        return null;
      }

      if (field.key && field.data?.formula) {
        model[field.key] = evaluateFormula(field.data?.formula, model);
      }

      if (
        groupIsHidden || // If parent group is hidden, remove it
        (field.hideExpression && !fireDynamicRule(field.hideExpression, field?.key))
      ) {
        removeFromModel(field?.key);
        removeFromValidation(field?.key);
        return null;
      }

      if (field.key && field.type) {
        const fieldComponent = getFieldTypeComponent(field.type);
        const componentProps: IComponentProps = {
          formId,
          field,
          customFunctions,
          modelValue: model[field.key],
          onValueUpdate,
          readonly: Boolean(mode === 'readonly' || readonlyKeys?.includes(field.key)),
          formState,
        };

        return React.createElement(fieldComponent, {
          key: field.key,
          ...componentProps,
          formState,
          clearErrors,
          ...useFormProps,
        });
      }
      return null;
    });
  }

  return (
    <>
      {mode !== 'readonly' && !hideMandatoryFieldMessage && <MandatoryFieldMessage />}
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid container spacing={3}>
          {renderFields(fields, undefined, step)}
          {mode !== 'readonly' && !model && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || !formState.isDirty || !formState.isValid}
              >
                {(settings ? settings[mode]?.save : null) || 'Submit'}
              </Button>
              {onCancel && settings && settings[mode]?.cancel && (
                <Button variant="contained" onClick={onCancel} disabled={submitting}>
                  {settings[mode]!.cancel}
                </Button>
              )}
              {submitting && <span>Submitting...</span>}
            </Grid>
          )}
        </Grid>
        {children}
        {!customButtons && !redSaveButton && mode !== 'readonly' && model && (
          <div className="clear">
            <div className="form-button-container">
              <div className="form-button-container--body">
                <div className="form-button-container--left"></div>
                <div className="form-button-container--right">
                  {onCancel && (
                    <button
                      id="cancel-button"
                      type="button"
                      className="mdl-button mdl-js-button mdl-js-ripple-effect"
                      disabled={submitting}
                      onClick={onCancel}
                    >
                      {settings?.manage?.cancel ?? settings?.create?.cancel ?? 'Cancel'}
                    </button>
                  )}
                  <button
                    id="save-button"
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect tlr-btn-save"
                    type="button"
                    disabled={
                      // react-hook-form says not valid despite no errors
                      (!formState.isValid && Object.keys(formState.errors).length > 0) ||
                      !formState.isDirty ||
                      extraRequiredKeys?.some((key) => !model[key]) ||
                      submitting
                    }
                    onClick={handleSubmit(handleOnSubmit)}
                  >
                    {settings?.manage?.save ?? settings?.create?.save ?? 'Save'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {redSaveButton && mode !== 'readonly' && (
          <div className="clear hidden-print">
            <div className="form__main-buttons" style={{ padding: '20px 0' }}>
              <Grid container spacing={1} justify="flex-end">
                {onCancel && (
                  <Grid item>
                    <Button disabled={submitting} onClick={onCancel}>
                      {settings?.manage?.cancel ?? settings?.create?.cancel ?? 'Cancel'}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      !formState.isValid || (!formState.isDirty && !ignoreDirty) || submitting
                    }
                    onClick={handleSubmit(handleOnSubmit)}
                  >
                    {redSaveButtonLabel}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

