import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useGetSiteAdmin } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { UpdateUserSettings } from 'components/Shared/UpdateUserSettings';
import React from 'react';
import { useParams } from 'react-router-dom';
import { GetSiteAdminQuery, SiteUserAdminItemFragment } from 'tillr-graphql';
import { AdminPageTitle } from '../Admin/AdminPageTitle';
import { AddUserButton } from './AddUserButton';
import { RemoveUser } from './RemoveUser';

export function ManageVenuePage() {
  const { siteId: siteIdString } = useParams<{ siteId: string }>();
  const siteId = Number(siteIdString);

  const getSiteState = useGetSiteAdmin({ id: siteId });

  function combineUsers(data: GetSiteAdminQuery): Array<{
    name?: string;
    email: string;
    isInvitationPending: boolean;
    userInfo?: SiteUserAdminItemFragment;
  }> {
    const users: ReturnType<typeof combineUsers> = [];

    if (data.siteAdmin?.users) {
      users.push(
        ...data.siteAdmin.users.map((user) => ({
          name: user.name,
          email: user.email,
          isInvitationPending: false,
          userInfo: user,
        })),
      );
    }

    if (data.siteAdmin?.pendingInvitations) {
      users.push(
        ...data.siteAdmin.pendingInvitations.map((invitation) => ({
          email: invitation.email,
          isInvitationPending: true,
        })),
      );
    }

    return users.sort((a, b) => a.email.localeCompare(b.email));
  }

  return (
    <>
      <AdminPageTitle
        title={getSiteState.data?.siteAdmin?.name ?? 'Manage venue'}
        crumbs={[{ name: 'Manage venues', to: '/admin/venues' }]}
      />
      <div className="page">
        <section>
          {getSiteState.loading && <ActivityIndicator />}
          {getSiteState.error && <ErrorDisplay error={getSiteState.error} />}
          {getSiteState.data?.siteAdmin && (
            <>
              <TableContainer component={Paper}>
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {combineUsers(getSiteState.data).map((user) => (
                      <TableRow hover key={user.email}>
                        <TableCell component="th" scope="row">
                          {user.isInvitationPending ? (
                            <Chip color="secondary" label="Invitation pending" />
                          ) : (
                            <Typography noWrap>{user.name}</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography>{user.email}</Typography>
                        </TableCell>
                        <TableCell>
                          {!user.isInvitationPending && user.userInfo && (
                            <Box display="flex">
                              <Box mr={1}>
                                <UpdateUserSettings
                                  name={user.userInfo.name}
                                  userId={user.userInfo.id}
                                  customData={user.userInfo.identity.customData}
                                />
                              </Box>
                              <RemoveUser siteId={siteId} user={user.userInfo} />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box my={2}>
                <AddUserButton siteId={siteId} />
              </Box>
            </>
          )}
        </section>
      </div>
    </>
  );
}

