import { useGetMyUserSettings } from 'hooks/useGetMyUserSettings';
import React, { useContext } from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router';
import UserProfileContext from 'UserProfileContext';
import { ActivityIndicator } from './ActivityIndicator';
import ErrorDisplay from './ErrorDisplay';
import { RegisterPage } from './Register/RegisterPage';

function RedirectWithSearch({ to, ...rest }: RedirectProps) {
  const { search } = useLocation();

  return <Redirect to={`${to}${search}`} {...rest} />;
}

export function LandingPage() {
  const { hash } = useLocation();

  const userProfile = useContext(UserProfileContext);
  const getMyUserSettingsState = useGetMyUserSettings(!userProfile);

  if (userProfile) {
    if (getMyUserSettingsState.error) {
      return (
        <div className="page">
          <ErrorDisplay error={getMyUserSettingsState.error} />
        </div>
      );
    }

    // Wait until user settings are loaded before deciding where to go
    if (getMyUserSettingsState.isResponsibleAuthority === undefined) {
      return (
        <div className="page">
          <ActivityIndicator />
        </div>
      );
    }

    if (getMyUserSettingsState.isResponsibleAuthority) {
      return <RedirectWithSearch to="/responsible-authority" />;
    }

    // Admin takes precedence over Chains
    if (userProfile.hasAnyPermission(['Admin'])) {
      return <RedirectWithSearch to="/admin" />;
    }
    if (userProfile.hasAnyPermission(['Chain.Manage'])) {
      return <RedirectWithSearch to="/chains" />;
    }
    if (userProfile.hasAnyPermission(['Reviewer'])) {
      return <RedirectWithSearch to="/review" />;
    }
    return <RedirectWithSearch to="/journey" />;
  }

  // TODO: update email link, remove this when all current email links have expired
  if (hash) {
    const searchIndex = hash.indexOf('?');
    if (searchIndex >= 0) {
      const loginHint = new URLSearchParams(hash.substring(searchIndex)).get('login_hint');
      if (loginHint) {
        return <Redirect to={`/authenticate?login_hint=${loginHint}`} />;
      }
    }
  }

  return <RegisterPage />;
}

