import Grid from '@material-ui/core/Grid';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useEffect } from 'react';
import HelpText from './shared/HelpText';
import { FieldValidationMessage, getRuleMessage } from './shared/Validation';

export default function SingleCheckbox(props: IComponentProps) {
  const { field, readonly, modelValue, onValueUpdate, register, errors, setValue, trigger } = props;

  useEffect(() => {
    register(
      { name: field.key },
      { required: { value: field.templateOptions?.required, message: getRuleMessage('required') } },
    );
    setValue(field.key, !!modelValue);
    if (modelValue) {
      trigger(field.key!);
    }
  }, [register, field, modelValue, setValue, trigger]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.checked);
      setValue(field.key!, !!event.currentTarget.checked);
      trigger(field.key!);
    }
  };

  const disabled = readonly || field.templateOptions?.disabled;

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <div className="checkbox formly-field">
          <label htmlFor={field.key} className="control-label">
            <input
              type="checkbox"
              id={field.key}
              className={`formly-field magic-checkbox ${disabled ? 'friendly-readonly' : ''}`}
              disabled={disabled}
              checked={Boolean(modelValue)}
              onChange={handleChange}
            />
            <label htmlFor={field.key} />
            {field.templateOptions?.label}{' '}
            {!field.templateOptions?.required && <span className="optional">(Optional)</span>}
          </label>

          {field.data?.help && <HelpText value={field.data?.help} />}
        </div>

        {errors[field.key!] && (
          <FieldValidationMessage className="non-input" message={errors[field.key!].message} />
        )}
        {field.data?.help && <HelpText value={field.data?.help} />}
      </Grid>
    </>
  );
}

