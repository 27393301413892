import { Box, Button, TextField } from '@material-ui/core';
import { useUpdateFormTemplateFieldOptionDetail } from 'apollo-hooks';
import { ASSESSMENT_FORM_ID, MODULE_ID } from 'appGlobals';
import ErrorDisplay from 'components/ErrorDisplay';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { ProgressButton } from 'components/Shared/ProgressButton';
import { RecommendationMarkdown } from 'components/Shared/RecommendationMarkdown';
import React, { ChangeEventHandler, useState } from 'react';
import { UpdateFormTemplateFieldOptionDetailMutationVariables } from 'tillr-graphql';

interface IProps {
  fieldId: string;
  index: number;
  recommendation: string | undefined;
}

export function RecommendationEditor(props: IProps) {
  const { fieldId, index, recommendation: initialRecommendation } = props;

  const [recommendation, setRecommendation] = useState(initialRecommendation);
  const [edit, setEdit] = useState(false);

  const [updateFieldOptionDetail, updateFieldOptionDetailState] =
    useUpdateFormTemplateFieldOptionDetail({
      id: ASSESSMENT_FORM_ID,
      siteId: 1,
      module: MODULE_ID,
    });

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setRecommendation(e.currentTarget.value);
  };

  const handleCancel = () => {
    setEdit(false);
    setRecommendation(initialRecommendation);
  };

  const handleUpdate = () => {
    const variables: UpdateFormTemplateFieldOptionDetailMutationVariables = {
      fieldOptionDetail: {
        formTemplateId: ASSESSMENT_FORM_ID,
        fieldId,
        index,
        detail: recommendation,
      },
    };
    updateFieldOptionDetail({ variables }).then((response) => {
      if (response.data) {
        setEdit(false);
      }
    });
  };

  return (
    // TODO: fix the styling
    <div className="collapsable collapsable-groups">
      <div className="collapsable-groups__group collapsable-groups__group--visible">
        <div className="collapsable-groups__body">
          {recommendation ? (
            <div className="recommendation recommendation-visible">
              <RecommendationMarkdown content={recommendation} />
            </div>
          ) : (
            <div className="alert alert--info alert--info-small">(No recommendation set)</div>
          )}
          {edit ? (
            <>
              <Box mt={2}>
                <TextField
                  variant="outlined"
                  multiline
                  value={recommendation}
                  onChange={handleChange}
                />
              </Box>
              {updateFieldOptionDetailState.error && (
                <ErrorDisplay error={updateFieldOptionDetailState.error} />
              )}
              <FormsButtons>
                <Button onClick={handleCancel}>Cancel</Button>
                <ProgressButton
                  onClick={handleUpdate}
                  label="Update"
                  loading={updateFieldOptionDetailState.loading}
                />
              </FormsButtons>
            </>
          ) : (
            <FormsButtons>
              <Button variant="contained" color="primary" onClick={() => setEdit(true)}>
                Edit
              </Button>
            </FormsButtons>
          )}
        </div>
      </div>
    </div>
  );
}

